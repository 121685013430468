import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

const RemarksForm = ({
  domain,
  show,
  remarks,
  handleClose,
  handleFormSubmit,
}) => {
  const [newRemark, setNewRemark] = useState("");

  const handleInputChange = (e) => {
    setNewRemark(e.target.value);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      style={{ backdropFilter: "blur(2px)" }}
    >
      <Modal.Header
        style={{ backgroundColor: "#FCF1E6" }}
        closeButton
      >
        <Modal.Title>
          <b>Remarks</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {remarks && remarks.length > 0 ? (
          <ul style={{ listStyle: "none", padding: 0 }}>
            {remarks.map((remark) => (
              <li
                key={remark._id}
                style={{
                  marginBottom: "10px",
                  padding: "10px",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "5px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <p style={{ margin: 0 }}>
                  <b>{remark.comment}</b>
                </p>
                <small>{new Date(remark.dateTime).toLocaleString()}</small>
              </li>
            ))}
          </ul>
        ) : (
          <p>No remarks yet.</p>
        )}
        <div style={{ marginTop: "10px" }}>
          <textarea
            value={newRemark}
            onChange={handleInputChange}
            placeholder="Enter your remark"
            style={{ width: "100%", padding: "10px", borderRadius: "5px" }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: "#FCF1E6",
        }}
      >
        <button
          type="button"
          className="btn btn-dark"
          onClick={() => {
            handleFormSubmit(newRemark);
            setNewRemark("");
          }}
        >
          Post remark <i className="fas fa-arrow-right"></i>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemarksForm;

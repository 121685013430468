import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Cookies from "js-cookie";

const CreateOrganization = ({
  domain,
  show,
  handleClose,
  handleFormSubmit,
}) => {
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    // Retrieve user permissions from cookies
    const userData = [
      Cookies.get("userId"),
      Cookies.get("userType"),
      Cookies.get("userName"),
      Cookies.get("userEmail"),
      Cookies.get("userPassword"),
      Cookies.get("userOrg"),
      Cookies.get("userPermissions"),
    ];

    const userId = userData[0];
    const userType = userData[1];
    const userName = userData[2];
    const userEmail = userData[3];
    const userPassword = userData[4];
    const userOrg = userData[5];
    const userPermissions = userData[6];

    // Define available options
    const availableOptions = [
      { value: "brand", label: "Company/Brand/Manufacturer" },
      { value: "enforcement", label: "Enforcement" },
      { value: "judiciary", label: "Judiciary" },
    ];

    setFilteredOptions(availableOptions);
  }, []);

  const createUser = () => {
    const organization = document.getElementById("organization").value;
    const type = document.getElementById("type").value;

    const data = {
      organization: organization,
      type: type,
    };

    fetch("/api/organizations/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        handleClose();
        handleFormSubmit();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <b>Add organization</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-floating mb-2">
          <input
            type="text"
            className="form-control"
            id="organization"
            name="organization"
            placeholder="Organization"
          />
          <label htmlFor="organization">Organization name*</label>
        </div>

        <select
          className="form-select mb-2"
          style={{ height: "60px" }}
          aria-label="Type"
          id="type"
          name="type"
        >
          <option value="">Select user type*</option>
          {filteredOptions.map((option) => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </select>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => createUser()}
          className="btn btn-danger btn-lg"
        >
          Submit <i className="fas fa-arrow-right"></i>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateOrganization;

import React, { useEffect, useState } from "react";
import Post from "../components/Post";
import PriorityForm from "../components/PriorityForm";
import StatusForm from "../components/StatusForm";
import RemarksForm from "../components/RemarksForm";
import { Link } from "react-router-dom";
import lottie from "lottie-web";
import BleepSpinnerDark from "../components/lottie/BleepSpinnerDark.json";

import { Helmet } from "react-helmet-async";

const Dashboard = ({ domain, userData }) => {
  const [userDataState, setUserDataState] = useState(userData);
  useEffect(() => {
    setUserDataState(userData);

    // console.log("User data:", userData[1]);
    // if (!userData.length || userData[1] === "user") {
    //   // window.location.href = "/signin";
    //   console.log("Redirecting to signin...");
    // }
  }, [userData]);

  const [currentPost, setCurrentPost] = useState(null);
  const [showPriorityFormModal, setShowPriorityFormModal] = useState(false);
  const [currentPriority, setCurrentPriority] = useState(null);
  const handleClosePriorityFormModal = () => {
    setShowPriorityFormModal(false);
  };

  const handleShowPriorityFormModal = (currentVal, post) => {
    setCurrentPost(post);
    setCurrentPriority(currentVal);
    setShowPriorityFormModal(true);
  };

  const handlePriorityFormSubmit = (newVal) => {
    console.log("Priority form submitted:", newVal);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      priority: newVal,
    });

    const requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`/api/tickets/${currentPost}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        fetchPosts(currentPage);
      })
      .catch((error) => console.error(error));
    handleClosePriorityFormModal();
  };

  const [showStatusFormModal, setShowStatusFormModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const handleCloseStatusFormModal = () => {
    setShowStatusFormModal(false);
  };

  const handleShowStatusFormModal = (currentVal, post) => {
    setCurrentPost(post);
    setCurrentStatus(currentVal);
    setShowStatusFormModal(true);
  };

  const handleStatusFormSubmit = (newVal) => {
    console.log("Status form submitted:", newVal);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      status: newVal,
    });

    const requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`/api/tickets/status/${currentPost}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        fetchPosts(currentPage);
      })
      .catch((error) => console.error(error));
    handleCloseStatusFormModal();
  };

  const [showRemarksFormModal, setShowRemarksFormModal] = useState(false);
  const [currentRemarks, setCurrentRemarks] = useState(null);
  const handleCloseRemarksFormModal = () => {
    setShowRemarksFormModal(false);
  };

  const handleShowRemarksFormModal = (currentVal, post) => {
    setCurrentPost(post);
    setCurrentRemarks(currentVal);
    setShowRemarksFormModal(true);
  };

  const handleRemarksFormSubmit = (newVal) => {
    if (!userDataState) {
      window.location.href = "/signin";
    }

    console.log("Remark submitted:", newVal);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      comment: newVal,
      user: userDataState[0],
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `http://localhost:3000/api/tickets/${currentPost}/remarks`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        fetchPosts(currentPage);
      })
      .catch((error) => console.error(error));
    handleCloseRemarksFormModal();
  };

  const [
    userId,
    userType,
    userName,
    userEmail,
    userPassword,
    userOrg,
    userPermissions,
    userAnonymous,
  ] = userDataState;

  const [posts, setPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const priorities = ["High", "Medium", "Low", "Spam"];
  const statuses = ["Pending action", "Ongoing action", "Resolved"];

  const getPriorityColor = (priority) => {
    switch (priority) {
      case "High":
        return "red";
      case "Medium":
        return "orange";
      case "Low":
        return "green";
      case "Spam":
        return "gray";
      default:
        return "gray";
    }
  };

  function convertDateTimeFormat(dateTimeString) {
    const dateTime = new Date(dateTimeString).toLocaleString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
    return dateTime;
  }

  const [currentPage, setCurrentPage] = useState(1); // Start from page 1
  const [totalPages, setTotalPages] = useState(1); // Total pages from API
  const pageSize = 10; // Number of posts per page

  const fetchPosts = (pageNumber = 1) => {
    let url = `/api/tickets/postTickets?page=${pageNumber}&size=${pageSize}`;

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setPosts(data.posts); // Replace instead of appending for pagination
        setTotalPages(data.totalPages); // Update total pages from API
        setCurrentPage(data.currentPage); // Update current page from API
        setHasMore(data.currentPage < data.totalPages); // Check if more pages are available
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching posts:", error);
        setIsLoading(false);
      });
  };

  const paginate = (pageNumber) => {
    setIsLoading(true); // Show loading while fetching
    fetchPosts(pageNumber); // Fetch data for selected page
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    const visiblePages = 3; // Show first/last 3 pages
    const buffer = 2; // Adjacent pages to show

    // Handle edge case when totalPages is 1
    if (totalPages === 1) {
      buttons.push(
        <button
          key={1}
          onClick={() => paginate(1)}
          className={`btn ${currentPage === 1 ? "btn-dark" : "btn-light"}`}
          style={{ margin: "5px" }}
        >
          1
        </button>
      );
      return buttons; // Return early since only one page exists
    }

    // First few pages
    for (let i = 1; i <= Math.min(visiblePages, totalPages); i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => paginate(i)}
          className={`btn ${i === currentPage ? "btn-dark" : "btn-light"}`}
          style={{ margin: "5px" }}
        >
          {i}
        </button>
      );
    }

    // Ellipsis if skipping pages
    if (currentPage > visiblePages + buffer) {
      buttons.push(
        <span
          key="dots1"
          style={{ margin: "5px", color: "white" }}
        >
          ...
        </span>
      );
    }

    // Middle pages around the current page
    const start = Math.max(visiblePages + 1, currentPage - buffer);
    const end = Math.min(totalPages - visiblePages, currentPage + buffer);
    for (let i = start; i <= end; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => paginate(i)}
          className={`btn ${i === currentPage ? "btn-dark" : "btn-light"}`}
          style={{ margin: "5px" }}
        >
          {i}
        </button>
      );
    }

    // Ellipsis if skipping pages
    if (currentPage < totalPages - visiblePages - buffer) {
      buttons.push(
        <span
          key="dots2"
          style={{ margin: "5px", color: "white" }}
        >
          ...
        </span>
      );
    }

    // Last few pages
    for (
      let i = Math.max(totalPages - visiblePages + 1, visiblePages + 1);
      i <= totalPages;
      i++
    ) {
      buttons.push(
        <button
          key={i}
          onClick={() => paginate(i)}
          className={`btn ${i === currentPage ? "btn-dark" : "btn-light"}`}
          style={{ margin: "5px" }}
        >
          {i}
        </button>
      );
    }

    return buttons;
  };

  // Fetch posts on component mount
  useEffect(() => {
    fetchPosts();
  }, [userId]);

  return (
    <>
      <Helmet>
        <title>BLEEP CRM</title>
        <meta
          name="description"
          content="Introducing BLEEP, the revolutionary crowdsourcing app designed to combat counterfeit products and services. Empowering users to report and verify the authenticity of goods, BLEEP connects you directly with brands and enforcement agents."
        />
      </Helmet>

      <div
        style={{
          paddingTop: "40px",
          paddingBottom: "180px",
          height: "400px",
          background:
            "linear-gradient(90deg, rgba(255, 64, 0, 0.914) 0%, rgb(255, 153, 1) 100%)",
        }}
      >
        <div
          className="bg-pattern"
          style={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100vh",
          }}
        ></div>
      </div>

      <div className="row m-0">
        <div
          className="col"
          style={{
            marginTop: "-180px",
            paddingTop: "10px",
            paddingInline: "0px",
            background:
              "linear-gradient(0deg, #150102 95%, rgba(255, 255, 255, 0)",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              marginTop: "-180px",
              marginBottom: "40px",
              backgroundColor: "#24120B",
              marginInline: "20px",
              padding: "20px",
              borderRadius: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <button className="btn btn-dark">
                <i className="fas fa-filter"></i> Filter
              </button>

              <button className="btn btn-dark">
                <i className="fas fa-download"></i> Export
              </button>
            </div>

            <div style={{ textAlign: "center", marginTop: "20px" }}>
              {renderPaginationButtons()}
            </div>

            <div
              style={{
                overflowX: "auto",
              }}
            >
              <table
                style={{
                  width: "100%",
                  borderCollapse: "separate",
                  borderSpacing: "0 15px",
                  backgroundColor: "transparent",
                  color: "#FFF",
                }}
              >
                <thead>
                  <tr>
                    {[
                      "#",
                      "ID",
                      "Date",
                      "Category",
                      "City",
                      "Amount",
                      "Description",
                      "Priority",
                      "Status",
                      "Remarks (By Admin)",
                      "Assign",
                    ].map((header, index) => (
                      <th
                        key={index}
                        style={{
                          padding: "15px",
                          textAlign: "left",
                          backgroundColor: "#2E1D17",
                          borderTopLeftRadius: index === 0 ? "20px" : "0",
                          borderTopRightRadius: index === 10 ? "20px" : "0",
                        }}
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {posts.map((row, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: "#3e1b0e",
                        borderRadius: "20px",
                      }}
                    >
                      <td
                        style={{
                          padding: "15px",
                          borderRadius: "20px 0 0 20px",
                        }}
                      >
                        {pageSize * (currentPage - 1) + index + 1}
                      </td>
                      <td style={{ padding: "15px" }}>
                        <a
                          target="_blank"
                          href={`/post/${row._id}`}
                          className="text-white"
                        >
                          #{row._id.slice(-6)}
                        </a>
                      </td>
                      <td style={{ padding: "15px", fontSize: "10px" }}>
                        {convertDateTimeFormat(row.dateTime)}
                      </td>
                      <td style={{ padding: "15px" }}>{row.category}</td>
                      <td style={{ padding: "15px" }}>{row.city}</td>
                      <td style={{ padding: "15px" }}>{row.price}</td>
                      <td style={{ padding: "15px" }}>
                        <textarea
                          defaultValue={row.description}
                          style={{
                            width: "100%",
                            backgroundColor: "#24120B",
                            color: "#FFF",
                            border: "1px solid #555",
                            borderRadius: "10px",
                            padding: "10px",
                            fontSize: "12px",
                          }}
                          rows={2}
                          disabled
                        />
                      </td>

                      <td style={{ padding: "15px", position: "relative" }}>
                        <span
                          style={{
                            padding: "10px",
                            backgroundColor: getPriorityColor(
                              row.ticket.priority
                            ),
                            color: "#FFF",
                            borderRadius: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            handleShowPriorityFormModal(
                              row.ticket.priority,
                              row._id
                            )
                          }
                        >
                          {!row.ticket.priority ? "None" : row.ticket.priority}
                        </span>
                      </td>
                      <td style={{ padding: "15px" }}>
                        <div
                          style={{ position: "relative", cursor: "pointer" }}
                          onClick={() =>
                            handleShowStatusFormModal(row.status, row._id)
                          }
                        >
                          {row.status === "Pending action" ? (
                            <span className="text-end status noaction">
                              <i className="far fa-clock" />
                            </span>
                          ) : row.status === "Ongoing action" ? (
                            <span className="text-end status action">
                              <i className="fas fa-hourglass-half spin" />
                            </span>
                          ) : row.status === "Resolved" ? (
                            <span className="text-end status resolved">
                              <i className="far fa-check-circle" />
                            </span>
                          ) : null}
                        </div>
                      </td>
                      <td style={{ padding: "15px" }}>
                        <button
                          className="btn btn-dark"
                          onClick={() =>
                            handleShowRemarksFormModal(
                              row.ticket?.remarks || [],
                              row._id
                            )
                          }
                        >
                          <i className="fas fa-comment"></i> View remarks (
                          {row.ticket?.remarks?.length || 0})
                        </button>
                      </td>

                      <td
                        style={{
                          padding: "15px",
                          borderRadius: "0 20px 20px 0",
                        }}
                      >
                        <button className="btn btn-dark">
                          <i className="fas fa-user"></i> Assign
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <PriorityForm
        domain={domain}
        show={showPriorityFormModal}
        currentVal={currentPriority}
        handleClose={handleClosePriorityFormModal}
        handleFormSubmit={handlePriorityFormSubmit}
      />

      <StatusForm
        domain={domain}
        show={showStatusFormModal}
        currentVal={currentStatus}
        handleClose={handleCloseStatusFormModal}
        handleFormSubmit={handleStatusFormSubmit}
      />

      <RemarksForm
        domain={domain}
        show={showRemarksFormModal}
        remarks={currentRemarks}
        handleClose={handleCloseRemarksFormModal}
        handleFormSubmit={handleRemarksFormSubmit}
      />
    </>
  );
};

export default Dashboard;

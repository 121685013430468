import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

const StatusForm = ({
  domain,
  show,
  currentVal,
  handleClose,
  handleFormSubmit,
}) => {
  const [selectedStatus, setSelectedStatus] = useState(currentVal);

  useEffect(() => {
    setSelectedStatus(currentVal);
  }, [currentVal]);

  const statuses = [
    {
      name: "Ongoing action",
      color: "#ffc400",
      icon: "fas fa-hourglass-half",
    },
    { name: "Resolved", color: "#2a9d00", icon: "fas fa-check-circle" },
  ];

  const handleStatusClick = (status) => {
    setSelectedStatus(status);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      style={{ backdropFilter: "blur(2px)" }}
    >
      <Modal.Header
        style={{ backgroundColor: "#FCF1E6" }}
        closeButton
      >
        <Modal.Title>
          <b>Set Status</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {statuses.map((status) => (
          <div
            key={status.name}
            onClick={() => handleStatusClick(status.name)}
            style={{
              backgroundColor:
                selectedStatus === status.name ? status.color : "#000000",
              borderRadius: "10px",
              padding: "20px",
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              border:
                selectedStatus === status.name
                  ? `1px solid ${status.color}`
                  : "1px solid transparent",
              cursor: "pointer",
            }}
          >
            <span style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              {[
                ...Array(
                  status.name === "High" ? 3 : status.name === "Medium" ? 2 : 1
                ),
              ].map((_, i) => (
                <i
                  key={i}
                  className={status.icon}
                ></i>
              ))}
              {status.name}
            </span>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: "#FCF1E6",
        }}
      >
        <button
          type="button"
          className="btn btn-dark"
          onClick={() => handleFormSubmit(selectedStatus)}
        >
          Update <i className="fas fa-arrow-right"></i>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default StatusForm;

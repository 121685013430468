import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import CreateAccount from "../components/CreateAccount";
import ConfirmModal from "../components/ConfirmModal";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Accounts = ({ domain, userData }) => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState("");

  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
  const handleCloseCreateAccountModal = () => {
    setShowCreateAccountModal(false);
  };
  const handleShowCreateAccountModal = () => {
    setShowCreateAccountModal(true);
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };
  const handleShowConfirmModal = () => {
    setShowConfirmModal(true);
  };

  const fetchUsers = () => {
    fetch("/api/users/admins")
      .then((response) => response.json())
      .then((data) => setUsers(data))
      .catch((error) => console.error("Error fetching admins:", error));
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleFormSubmit = () => {
    fetchUsers();
  };

  function formatPermissions(permissionsString) {
    if (!permissionsString) return ""; // If permissions string is null or undefined, return empty string

    // Parse the stringified JSON into an object
    const permissions = JSON.parse(permissionsString);

    // Filter out the true permissions and join them with commas
    const truePermissions = Object.keys(permissions).filter(
      (key) => permissions[key] === true
    );
    return truePermissions.join(", ");
  }

  const handleDeleteUser = (userId) => {
    handleShowConfirmModal();
    setUserId(userId);
  };

  const handleProceed = () => {
    fetch(`/api/users/${userId}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.ok) {
          fetchUsers();
        } else {
          console.error("Error deleting user:", response);
        }
      })
      .catch((error) => console.error("Error deleting user:", error));
    handleCloseConfirmModal();
  };

  return (
    <>
      <Helmet>
        <title>Accounts | BLEEP</title>
        <meta
          name="description"
          content="Introducing BLEEP, the revolutionary crowdsourcing app designed to combat counterfeit products and services. Empowering users to report and verify the authenticity of goods, BLEEP connects you directly with brands and enforcement agents."
        />
      </Helmet>

      <div
        style={{
          paddingLeft: "20px",
          paddingTop: "20px",
          background:
            "linear-gradient(90deg, rgb(255, 72, 0) 0%, rgb(255, 124, 1) 100%)",
        }}
      >
        <div
          className="bg-pattern"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
          }}
        ></div>
        <div
          className="row d-flex align-items-center"
          style={{
            position: "relative",
          }}
        >
          <div
            className="col"
            style={{ color: "white", padding: "20px" }}
          >
            <div
              className="col d-flex align-items-center"
              style={{ color: "white", flexDirection: "row" }}
            >
              <Link
                to="/"
                className="text-decoration-none text-white"
              >
                <span
                  style={{
                    backgroundColor: "#00000089",
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    marginRight: "10px",
                  }}
                >
                  <i className="fas fa-chevron-left"></i>
                </span>
              </Link>

              <div>
                <b
                  style={{
                    fontSize: "30px",
                    textShadow: "#1c1c1c 1px 2px 0px",
                  }}
                >
                  Accounts
                </b>
              </div>
            </div>

            <p
              style={{ paddingTop: "10px", textShadow: "#1c1c1c 1px 1px 0px" }}
            >
              Manage organization accounts on BLEEP
            </p>

            <button
              type="button"
              className="btn btn-light btn-lg bleep-btn"
              onClick={handleShowCreateAccountModal}
            >
              <span style={{ marginRight: "10px" }}>
                <i className="fa-solid fa-plus-circle"></i>
              </span>
              <b>Add</b>
            </button>
          </div>
        </div>
      </div>

      <div
        className="container p-4"
        style={{ overflowY: "auto", position: "relative" }}
      >
        <table
          style={{
            width: "100%",
            borderCollapse: "separate",
            borderSpacing: "0 15px",
            backgroundColor: "transparent",
            color: "#FFF",
          }}
        >
          <thead>
            <tr>
              {[
                "#",
                "Type",
                "Display-name",
                "Email",
                "Organization",
                "Permissions",
                "Action",
              ].map((header, index) => (
                <th
                  key={index}
                  style={{
                    padding: "15px",
                    textAlign: "left",
                    backgroundColor: "#2E1D17",
                    borderTopLeftRadius: index === 0 ? "20px" : "0",
                    borderTopRightRadius: index === 6 ? "20px" : "0",
                  }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr
                style={{
                  backgroundColor: "#3e1b0e",
                  borderRadius: "20px",
                }}
              >
                <td
                  style={{
                    padding: "15px",
                    borderRadius: "20px 0 0 20px",
                  }}
                >
                  {users.indexOf(user) + 1}
                </td>
                <td style={{ padding: "15px" }}>{user.type}</td>
                <td style={{ padding: "15px" }}>{user.username}</td>
                <td style={{ padding: "15px" }}>{user.email}</td>
                <td style={{ padding: "15px" }}>{user.organization}</td>
                <td style={{ padding: "15px" }}>
                  {formatPermissions(user.permissions)}
                </td>
                <td
                  style={{
                    padding: "15px",
                    borderRadius: "0 20px 20px 0",
                  }}
                >
                  {user.type != "superadmin" && (
                    <a href="#">
                      <i
                        onClick={() => handleDeleteUser(user._id)}
                        className="fas fa-trash-alt text-danger"
                      ></i>
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <CreateAccount
        domain={domain}
        show={showCreateAccountModal}
        handleClose={handleCloseCreateAccountModal}
        handleFormSubmit={handleFormSubmit}
      />
      <ConfirmModal
        show={showConfirmModal}
        handleClose={handleCloseConfirmModal}
        handleProceed={handleProceed}
        confirmMessage="CAUTION: Are you sure you want to delete this user? This cannot be
        undone!"
      />
    </>
  );
};

export default Accounts;

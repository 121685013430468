import React, { useEffect, useState } from "react";
import { createAvatar } from "@dicebear/core";
import { identicon } from "@dicebear/collection";
import Comment from "./Comment";

const Thread = ({
  comment,
  handleUpdateComments,
  handleShowEmailAuthModal,
  postStatus,
  replies,
  userId,
}) => {
  return (
    <>
      {/* Comment component */}
      <Comment
        comment={comment}
        handleUpdateComments={handleUpdateComments}
        postStatus={postStatus}
        handleShowEmailAuthModal={handleShowEmailAuthModal}
        userId={userId}
      />

      {/* Replies for each comment if exist... */}
      {replies.map((reply) => (
        <Comment
          key={reply._id}
          comment={reply}
          handleUpdateComments={handleUpdateComments}
          postStatus={postStatus}
          handleShowEmailAuthModal={handleShowEmailAuthModal}
          userId={userId}
          isReply={true}
          thread={comment._id}
        />
      ))}
    </>
  );
};

export default Thread;

import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

const PriorityForm = ({
  domain,
  show,
  currentVal,
  handleClose,
  handleFormSubmit,
}) => {
  const [selectedPriority, setSelectedPriority] = useState(currentVal);

  useEffect(() => {
    setSelectedPriority(currentVal);
  }, [currentVal]);

  const priorities = [
    { name: "High", color: "red", icon: "fas fa-exclamation-triangle" },
    { name: "Medium", color: "orange", icon: "fas fa-exclamation-triangle" },
    { name: "Low", color: "green", icon: "fas fa-exclamation-triangle" },
    { name: "Spam", color: "gray", icon: "fas fa-exclamation-circle" },
  ];

  const handlePriorityClick = (priority) => {
    setSelectedPriority(priority);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      style={{ backdropFilter: "blur(2px)" }}
    >
      <Modal.Header
        style={{ backgroundColor: "#FCF1E6" }}
        closeButton
      >
        <Modal.Title>
          <b>Set Priority</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {priorities.map((priority) => (
          <div
            key={priority.name}
            onClick={() => handlePriorityClick(priority.name)}
            style={{
              backgroundColor:
                selectedPriority === priority.name ? priority.color : "#000000",
              borderRadius: "10px",
              padding: "20px",
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              border:
                selectedPriority === priority.name
                  ? `1px solid ${priority.color}`
                  : "1px solid transparent",
              cursor: "pointer",
            }}
          >
            <span style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              {[
                ...Array(
                  priority.name === "High"
                    ? 3
                    : priority.name === "Medium"
                    ? 2
                    : 1
                ),
              ].map((_, i) => (
                <i
                  key={i}
                  className={priority.icon}
                ></i>
              ))}
              {priority.name}
            </span>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: "#FCF1E6",
        }}
      >
        <button
          type="button"
          className="btn btn-dark"
          onClick={() => handleFormSubmit(selectedPriority)}
        >
          Update <i className="fas fa-arrow-right"></i>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PriorityForm;

import React, { useEffect, useState } from "react";
import Post from "../components/Post";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const MyBleeps = ({ domain, userData }) => {
  const [userId, setUserId] = useState(null);
  const [userType, setUserType] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userPassword, setUserPassword] = useState(null);
  const [userOrg, setUserOrg] = useState(null);
  const [userPermissions, setUserPermissions] = useState(null);

  const getUserData = () => {
    // Retrieve user data from cookies
    const userIdFromCookie = Cookies.get("userId");
    const userTypeFromCookie = Cookies.get("userType");
    const userNameFromCookie = Cookies.get("userName");
    const userEmailFromCookie = Cookies.get("userEmail");
    const userPasswordFromCookie = Cookies.get("userPassword");
    const userOrgFromCookie = Cookies.get("userOrg");
    const userPermissionsFromCookie = Cookies.get("userPermissions");

    // Update state with user data
    setUserId(userIdFromCookie);
    setUserType(userTypeFromCookie);
    setUserName(userNameFromCookie);
    setUserEmail(userEmailFromCookie);
    setUserPassword(userPasswordFromCookie);
    setUserOrg(userOrgFromCookie);
    setUserPermissions(userPermissionsFromCookie);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getUserData();
      if (userId) {
        fetchPosts();
      }
    };

    fetchData();
  }, [userId]);

  const [posts, setPosts] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchPosts = () => {
    let url = "/api/posts/userbleeps";
    if (userId) {
      url += `?user=${userId}`;
    }

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setPosts(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching posts:", error);
        setIsLoading(false);
      });
  };

  const handleShowSignIn = () => {
    window.location.href = "/signin?redirect=" + window.location.pathname;
  };

  const refreshPosts = () => {
    fetchPosts();
  };

  return (
    <>
      <Helmet>
        <title>My Bleeps | BLEEP</title>
        <meta
          name="description"
          content="Introducing BLEEP, the revolutionary crowdsourcing app designed to combat counterfeit products and services. Empowering users to report and verify the authenticity of goods, BLEEP connects you directly with brands and enforcement agents."
        />
      </Helmet>

      <div
        style={{
          paddingLeft: "20px",
          paddingTop: "20px",
          background:
            "linear-gradient(90deg, rgb(255, 72, 0) 0%, rgb(255, 124, 1) 100%)",
        }}
      >
        <div
          className="bg-pattern"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
          }}
        ></div>
        <div
          className="row d-flex align-items-center"
          style={{
            position: "relative",
          }}
        >
          <div
            className="col"
            style={{ color: "white", padding: "20px" }}
          >
            <div
              className="col d-flex align-items-center"
              style={{ color: "white", flexDirection: "row" }}
            >
              <Link
                to="/"
                className="text-decoration-none text-white"
              >
                <span
                  style={{
                    backgroundColor: "#00000089",
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    marginRight: "10px",
                  }}
                >
                  <i className="fas fa-chevron-left"></i>
                </span>
              </Link>

              <div>
                <b
                  style={{
                    fontSize: "30px",
                    textShadow: "#1c1c1c 1px 2px 0px",
                  }}
                >
                  My Bleeps
                </b>
              </div>
            </div>
            <p
              style={{ paddingTop: "10px", textShadow: "#1c1c1c 1px 1px 0px" }}
            >
              You can find all the bleeps you post here!
            </p>
          </div>
        </div>
      </div>

      <div
        className="row m-0"
        style={{ padding: "20px", position: "relative" }}
      >
        <div className="col">
          {posts?.length > 0 ? (
            <div className="row row-cols-lg-4 row-cols-md-3 row-cols-sm-1 row-cols-1">
              {posts.map((post) => (
                <Post
                  key={post._id}
                  post={post}
                  showSignIn={handleShowSignIn}
                  refreshPosts={refreshPosts}
                />
              ))}
            </div>
          ) : (
            <div
              className="empty col-12"
              style={{
                color: "white",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                paddingTop: "50px",
              }}
            >
              <b>
                {isLoading ? (
                  <i
                    className="fas fa-circle-notch fa-spin"
                    style={{ fontSize: 80, color: "#e67128" }}
                  ></i>
                ) : (
                  "You haven't posted any bleep reports yet!"
                )}
              </b>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MyBleeps;

import React, { useEffect, useState } from "react";
import { createAvatar } from "@dicebear/core";
import { identicon } from "@dicebear/collection";

const Comment = ({
  comment,
  handleUpdateComments,
  postStatus,
  handleShowEmailAuthModal,
  userId,
  isReply,
  thread,
}) => {
  //   const timeDifferenceInMinutes = Math.floor(
  //     (new Date() - new Date(comment.dateTime)) / (1000 * 60)
  //   );

  const [showReplyInput, setShowReplyInput] = useState(false);
  const [reply, setReply] = useState("");

  const handleReplyInput = () => {
    setShowReplyInput(!showReplyInput);
    setReply("");
  };

  const avatar = React.useMemo(() => {
    if (!comment || !comment.displayName) return null;
    return createAvatar(identicon, {
      seed: comment.displayName,
    }).toDataUri();
  }, [comment?.displayName]);

  function convertDateTimeFormat(dateTimeString) {
    const dateTime = new Date(dateTimeString).toLocaleString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return dateTime;
  }

  const handleDelete = (commentId) => {
    const url = isReply
      ? `/api/commentReplies/${commentId}`
      : `/api/comments/${commentId}`;

    fetch(url, {
      method: "DELETE",
    })
      .then((res) => {
        if (res.ok) {
          handleUpdateComments();
        } else {
          console.error("Error deleting comment:", res.statusText);
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  };

  const postReply = (commentId, reply) => {
    if (!userId) {
      handleShowEmailAuthModal();
      return;
    }

    // Ensure comment exists and has required fields
    if (!comment?.post || !comment?._id) {
      console.error("Invalid comment data.");
      return;
    }

    const raw = JSON.stringify({
      user: userId,
      post: comment.post,
      replyTo: comment.user._id,
      // if it's a reply to a reply, set the thread to the original comment
      thread: thread || comment._id,
      comment: reply,
    });

    fetch(`/api/commentReplies`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Added header
      },
      body: raw,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        handleUpdateComments();
        handleReplyInput();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div
      style={{
        marginLeft: isReply ? "50px" : "0",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "10px",
          gap: "10px",
        }}
      >
        {/* {isReply && (
          <div
            style={{
              position: "absolute",
              width: "1px",
              height: "100px",
              marginLeft: "-30px",
              marginTop: "-100px",
              backgroundColor: "white",
            }}
          ></div>
        )} */}
        <div
          style={{
            width: "40px",
          }}
        >
          <div
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              backgroundColor: "#ffffff",
            }}
          >
            <img
              src={avatar}
              alt="Avatar"
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </div>
        </div>

        {/* Pin comment automatically for admins, not for regular users */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {comment.user && comment.user.type !== "user" && !isReply && (
            <span
              style={{
                color: "white",
                fontSize: "12px",
                marginBottom: "5px",
              }}
            >
              <i className="fas fa-thumbtack"></i> Pinned
            </span>
          )}
          <div
            style={{
              border:
                comment.user?.type === "user" ? "0px" : "1px solid #ff8000",
              // boxShadow:
              //   comment.user?.type === "user" ? "none" : "0px 0px 10px #ff8000",
              backgroundColor: "#00000062",
              flexDirection: "column",
              width: "100%",
              padding: 10,
              borderRadius: 10,
            }}
          >
            <div>
              {comment.user && comment.user.type !== "user" && (
                <span
                  style={{
                    backgroundColor: "#ff8000",
                    color: "#000000",
                    paddingInline: "5px",
                    paddingBlock: "2px",
                    borderRadius: "10px",
                    fontSize: "12px",
                  }}
                >
                  {comment.user.organization} <i className="fas fa-check"></i>
                </span>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontSize: "12px",
                  }}
                >
                  @{comment.displayName}
                </span>
                <span
                  style={{
                    color: "#ffffff76",
                    fontSize: "10px",
                  }}
                >
                  {convertDateTimeFormat(comment.dateTime)}
                </span>
              </div>
            </div>
            {isReply && (
              <div>
                {/* Show reply user */}
                <span
                  style={{
                    backgroundColor: "#633305",
                    color: "#ffffff",
                    paddingInline: "5px",
                    paddingBlock: "2px",
                    borderRadius: "5px",
                    fontSize: "12px",
                  }}
                >
                  Replying to @{comment?.replyToDisplayName}
                </span>
              </div>
            )}
            <div>
              {/* Contents of the comment */}
              <span
                style={{
                  color: "white",
                  fontSize: "12px",
                }}
              >
                {comment.comment}
              </span>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "5px",
            }}
          >
            {/* Reply button */}
            {postStatus !== "Resolved" && (
              <div
                style={{
                  paddingTop: 5,
                  color: "#ff8000",
                  fontSize: "12px",
                  cursor: "pointer",
                }}
                onClick={handleReplyInput}
              >
                <span>
                  <i className="fas fa-reply"></i> Reply
                </span>
              </div>
            )}

            {/* Delete Button */}
            {/* check if user id is comment user id */}
            {comment.user && comment.user._id === userId && (
              <div
                style={{
                  paddingTop: 5,
                  color: "#ff5050",
                  fontSize: "12px",
                  cursor: "pointer",
                }}
                onClick={() => handleDelete(comment._id)}
              >
                <span>
                  <i className="fas fa-trash-alt"></i> Delete
                </span>
              </div>
            )}
          </div>

          {/* Nested Comments, View replies button */}
          {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            paddingBottom: "10px",
          }}
        >
          <div
            style={{
              paddingTop: 5,
              color: "#ffffff",
              fontSize: "12px",
              cursor: "pointer",
            }}
          >
            <span>
              <i className="fas fa-chevron-down"></i> View 2 replies
            </span>
          </div>
        </div> */}
        </div>
      </div>

      {showReplyInput && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#00000062",
            borderRadius: "10px",
            padding: "10px",
            marginLeft: "50px",
            marginBottom: "20px",
          }}
        >
          <textarea
            className="comment-input"
            autoFocus
            onChange={(e) => setReply(e.target.value)}
            value={reply}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <div>
              {/* <button
                type="button"
                className="comment-file-btn"
                disabled={post.status === "Resolved"}
              >
                <i className="fas fa-paperclip"></i>
              </button> */}
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
              }}
            >
              <button
                type="button"
                className="comment-cancel-btn"
                onClick={handleReplyInput}
              >
                Cancel
              </button>
              <button
                type="button"
                className="comment-post-btn"
                onClick={() => postReply(comment._id, reply)}
              >
                Comment
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Comment;
